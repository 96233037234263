<!-- 短信模板变量设置模块 -->
<template>
  <div>
    <a-modal
      title="短信模板设置"
      width="60%"
      okText="确定"
      cancelText="取消"
      v-model:visible.sync="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div>
        <a-row :gutter="[8, 8]">
          <a-col :span="12">
            <div class="divAreaCss">
              <a-card title="短信模板变量列表">
                <div class="tool">
                  <div>
                    <a-input-search
                      v-model:value="keyword"
                      placeholder="请输入关键词"
                      enter-button
                      @search="onSearch"
                      @change.stop="onSearchChange"
                    />
                  </div>
                  <div>
                    <a-button
                      type="primary"
                      @click="handleAdd"
                      style="margin-bottom: 8px"
                      :disabled="addBtnDisabled"
                    >
                      <PlusOutlined />添加
                    </a-button>
                  </div>
                </div>
                <div>
                  <a-table
                    bordered
                    :data-source="dictList"
                    :columns="main_columns"
                    :pagination="false"
                    :rowKey="(record) => record.id"
                    :customRow="customRow"
                  >
                    <template
                      v-for="col in ['name', 'code']"
                      #[col]="{ text, record }"
                      :key="col"
                    >
                      <div>
                        <a-input
                          v-if="editableData[record.id]"
                          v-model:value="editableData[record.id][col]"
                          style="margin: -5px 0"
                        />
                        <template v-else>
                          {{ text }}
                        </template>
                      </div>
                    </template>
                    <template #operation="{ record }">
                      <div>
                        <span v-if="editableData[record.id]">
                          <a-button
                            type="link"
                            @click.stop="handleSave(record.id)"
                            ><SaveOutlined />保存</a-button
                          >
                          <a-popconfirm
                            title="您确定要取消编辑吗?"
                            okText="确定"
                            cancelText="取消"
                            @confirm="handleCancel(record.id)"
                          >
                            <a-button type="link"
                              ><UndoOutlined />取消</a-button
                            >
                          </a-popconfirm>
                        </span>
                        <span v-else>
                          <a-button
                            type="link"
                            @click.stop="handleEdit(record.id)"
                            ><EditOutlined />编辑</a-button
                          >
                          <a-popconfirm
                            title="您确信要执行删除吗?"
                            okText="确定"
                            cancelText="取消"
                            @confirm="handleDelete(record.id)"
                          >
                            <a-button type="link"
                              ><DeleteOutlined />删除</a-button
                            >
                          </a-popconfirm>
                        </span>
                      </div>
                    </template>
                  </a-table>
                  <div class="pagination">
                    <a-pagination
                      :current="pagination.pageIndex"
                      :pageSize="pagination.pageSize"
                      :total="pagination.total"
                      :page-size-options="pagination.pageSizeOptions"
                      :showTotal="(total) => `共 ${total} 条记录`"
                      show-size-changer
                      @change="onChangePage"
                      @showSizeChange="onShowSizeChange"
                    >
                      <template #buildOptionText="props">
                        <span>{{ props.value }}条/页</span>
                      </template>
                    </a-pagination>
                  </div>
                </div>
              </a-card>
            </div>
          </a-col>
          <a-col :span="12">
            <div class="divAreaCss">
              <a-card title="数据字典属性列表">
                <div class="tool">
                  <div>
                    当前数据字典【<span style="color: red">{{
                      currentName
                    }}</span
                    >】
                  </div>
                  <div>
                    <a-button
                      type="primary"
                      @click="handleAdd1"
                      style="margin-bottom: 8px"
                      :disabled="addBtnDisabled1"
                    >
                      <PlusOutlined />添加
                    </a-button>
                  </div>
                </div>
                <div>
                  <a-table
                    bordered
                    :data-source="dictItemList"
                    :columns="sub_columns"
                    :pagination="false"
                    :rowKey="(record) => record.id"
                  >
                    <template
                      v-for="col in ['item', 'value']"
                      #[col]="{ text, record }"
                      :key="col"
                    >
                      <div>
                        <a-input
                          v-if="editableData1[record.id]"
                          v-model:value="editableData1[record.id][col]"
                          style="margin: -5px 0"
                        />
                        <template v-else>
                          {{ text }}
                        </template>
                      </div>
                    </template>
                    <template #operation1="{ record }">
                      <div>
                        <span v-if="editableData1[record.id]">
                          <a-button
                            type="link"
                            @click.stop="handleSave1(record.id)"
                            ><SaveOutlined />保存</a-button
                          >
                          <a-popconfirm
                            title="您确定要取消编辑吗?"
                            okText="确定"
                            cancelText="取消"
                            @confirm="handleCancel1(record.id)"
                          >
                            <a-button type="link"
                              ><UndoOutlined />取消</a-button
                            >
                          </a-popconfirm>
                        </span>
                        <span v-else>
                          <a-button
                            type="link"
                            @click.stop="handleEdit1(record.id)"
                            ><EditOutlined />编辑</a-button
                          >
                          <a-popconfirm
                            title="您确信要执行删除吗?"
                            okText="确定"
                            cancelText="取消"
                            @confirm="handleDelete1(record.id)"
                          >
                            <a-button type="link"
                              ><DeleteOutlined />删除</a-button
                            >
                          </a-popconfirm>
                        </span>
                      </div>
                    </template>
                  </a-table>
                  <div class="pagination">
                    <a-pagination
                      :current="pagination1.pageIndex"
                      :pageSize="pagination1.pageSize"
                      :total="pagination1.total"
                      :page-size-options="pagination1.pageSizeOptions"
                      :showTotal="(total) => `共 ${total} 条记录`"
                      show-size-changer
                      @change="onChangePage1"
                      @showSizeChange="onShowSizeChange1"
                    >
                      <template #buildOptionText="props">
                        <span>{{ props.value }}条/页</span>
                      </template>
                    </a-pagination>
                  </div>
                </div>
              </a-card>
            </div>
          </a-col>
        </a-row>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, defineComponent } from "vue";
import {
  PlusOutlined,
  EditOutlined,
  SaveOutlined,
  DeleteOutlined,
  UndoOutlined,
} from "@ant-design/icons-vue";
import { cloneDeep } from "lodash-es";
import { message } from "ant-design-vue";
import {
  getDictionaryListPage,
  getDictItemListPage,
  saveDictionary,
  deleteDictionary,
  saveDictionaryItem,
  deleteDictionaryItem,
} from "@/api/system/dictionary";
export default defineComponent({
  name: "MessageCreateForm",
  components: {
    PlusOutlined,
    EditOutlined,
    SaveOutlined,
    DeleteOutlined,
    UndoOutlined,
  },
  setup() {
    const state = reactive({
      loading: false,
      loading1: false,
      addBtnDisabled: false,
      addBtnDisabled1: false,
      keyword: "",
      currentName: "",
      main_columns: [
        {
          title: "字典名称",
          dataIndex: "name",
          width: "30%",
          slots: {
            customRender: "name",
          },
        },
        {
          title: "字典编码",
          dataIndex: "code",
          width: "30%",
          slots: {
            customRender: "code",
          },
          sorter: (a, b) => {
            if (a.code < b.code) {
              return -1;
            }
            if (a.code > b.code) {
              return 1;
            }
            return 0;
          },
        },
        {
          title: "操作",
          dataIndex: "operation",
          slots: {
            customRender: "operation",
          },
        },
      ],
      sub_columns: [
        {
          title: "字典属性",
          dataIndex: "item",
          width: "40%",
          slots: {
            customRender: "item",
          },
        },
        {
          title: "字典值",
          dataIndex: "value",
          width: "20%",
          slots: {
            customRender: "value",
          },
          sorter: (a, b) => a.value - b.value,
        },
        {
          title: "操作",
          dataIndex: "operation1",
          slots: {
            customRender: "operation1",
          },
        },
      ],
      dictList: [],
      dictItemList: [],
      searchParam: {
        searchValue: "",
        pageIndex: 1,
        pageSize: 5,
      },
      searchParam1: {
        code: "",
        pageIndex: 1,
        pageSize: 5,
      },
      pagination: {
        pageIndex: 1, // 默认显示第1页
        pageSize: 5, // 默认每页显示数量
        pageSizeOptions: ["5", "10", "20", "50"], // 每页数量选项
        total: 0, //总条数
      },
      pagination1: {
        pageIndex: 1, // 默认显示第1页
        pageSize: 5, // 默认每页显示数量
        pageSizeOptions: ["5", "10", "20", "50"], // 每页数量选项
        total: 0, //总条数
      },
    });
    onMounted(() => {
      initDictList(state.searchParam);
    });
    const editableData = reactive({});
    const editableData1 = reactive({});
    const onSearch = () => {
      state.searchParam = {
        searchValue: state.keyword,
        pageIndex: 1,
        pageSize: 5,
      };
      initDictList(state.searchParam);
    };
    const onSearchChange = () => {
      //state.keyword = e.data;
      state.searchParam.searchValue = state.keyword;
    };
    const customRow = (record) => {
      return {
        onDblclick: () => {
          state.searchParam1 = {
            code: record.code,
            pageIndex: 1,
            pageSize: 5,
          };
          state.currentName = record.name;
          initDictItemList(state.searchParam1);
        },
      };
    };
    const onChangePage = (page, pageSize) => {
      if (state.keyword) {
        state.searchParam.searchValue = state.keyword;
      }
      state.pagination.pageIndex = page;
      state.searchParam.pageIndex = page;
      state.searchParam.pageSize = pageSize;
      initDictList(state.searchParam);
    };
    const onShowSizeChange = (page, pageSize) => {
      if (state.keyword) {
        state.searchParam.searchValue = state.keyword;
      }
      state.pagination.pageSize = pageSize;
      state.pagination.pageIndex = 1;
      state.searchParam.pageIndex = 1;
      state.searchParam.pageSize = pageSize;
      initDictList(state.searchParam);
    };
    const onChangePage1 = (page, pageSize) => {
      state.pagination1.pageIndex = page;
      state.searchParam1.pageIndex = page;
      state.searchParam1.pageSize = pageSize;
      initDictItemList(state.searchParam1);
    };
    const onShowSizeChange1 = (page, pageSize) => {
      state.pagination1.pageSize = pageSize;
      state.pagination1.pageIndex = 1;
      state.searchParam1.pageIndex = 1;
      state.searchParam1.pageSize = pageSize;
      initDictItemList(state.searchParam1);
    };

    const handleEdit = (key) => {
      state.addBtnDisabled = true;
      editableData[key] = cloneDeep(
        state.dictList.filter((item) => key === item.id)[0]
      );
    };

    const handleEdit1 = (key) => {
      state.addBtnDisabled1 = true;
      editableData1[key] = cloneDeep(
        state.dictItemList.filter((item) => key === item.id)[0]
      );
    };

    const handleAdd = () => {
      state.addBtnDisabled = true;
      const newData = {
        id: 9999,
        name: null,
        code: null,
      };
      state.dictList.push(newData);
      editableData[9999] = cloneDeep(
        state.dictList.filter((item) => item.id === 9999)[0]
      );
    };

    const handleAdd1 = () => {
      state.addBtnDisabled1 = true;
      const newData = {
        id: 9999,
        code: state.searchParam1.code,
        item: null,
        value: null,
      };
      state.dictItemList.push(newData);
      editableData1[9999] = cloneDeep(
        state.dictItemList.filter((item) => item.id === 9999)[0]
      );
    };

    const handleCancel = (key) => {
      state.addBtnDisabled = false;
      delete editableData[key];
      if (key === 9999) {
        state.dictList = state.dictList.filter((item) => item.id != 9999);
      }
    };

    const handleCancel1 = (key) => {
      state.addBtnDisabled1 = false;
      delete editableData1[key];
      if (key === 9999) {
        state.dictItemList = state.dictItemList.filter(
          (item) => item.id != 9999
        );
      }
    };

    const handleSave = (key) => {
      if (editableData[key]["name"] == "") {
        message.error("字典名称不能为空！！！");
      } else if (editableData[key]["code"] == null) {
        message.error("字典编码不能为空！！！");
      } else {
        saveDictionary(editableData[key]).then((resp) => {
          if (resp.code == 200) {
            Object.assign(
              state.dictList.filter((item) => key === item.id)[0],
              editableData[key]
            );
            if (state.keyword) {
              state.searchParam.searchValue = state.keyword;
            }
            if (key === 9999) {
              let total = state.pagination.total + 1;
              let lastPage = Math.ceil(total / state.pagination.pageSize);
              state.pagination.pageIndex = lastPage;
              state.searchParam.pageIndex = lastPage;
            }
            initDictList(state.searchParam);
            state.addBtnDisabled = false;
            delete editableData[key];
          }
        });
      }
    };

    const handleSave1 = (key) => {
      if (editableData1[key]["item"] == "") {
        message.error("字典属性不能为空！！！");
      } else if (editableData1[key]["value"] == null) {
        message.error("字典属性值不能为空！！！");
      } else {
        saveDictionaryItem(editableData1[key]).then((resp) => {
          if (resp.code == 200) {
            Object.assign(
              state.dictItemList.filter((item) => key === item.id)[0],
              editableData1[key]
            );
            let total = state.pagination1.total + 1;
            let lastPage = Math.ceil(total / state.pagination1.pageSize);
            state.pagination1.pageIndex = lastPage;
            state.searchParam1.pageIndex = lastPage;
            initDictItemList(state.searchParam1);
            state.addBtnDisabled1 = false;
            delete editableData1[key];
          }
        });
      }
    };

    const handleDelete = (key) => {
      deleteDictionary({ id: key, logic: false }).then((resp) => {
        if (resp.code == 200) {
          let total = state.pagination.total - 1;
          let lastPage = Math.ceil(total / state.pagination.pageSize);
          if (lastPage < state.pagination.pageIndex) {
            state.pagination.pageIndex = lastPage;
            state.searchParam.pageIndex = lastPage;
          }
          initDictList(state.searchParam);
        }
      });
    };

    const handleDelete1 = (key) => {
      deleteDictionaryItem({ id: key, logic: false }).then((resp) => {
        if (resp.code == 200) {
          let total = state.pagination1.total - 1;
          let lastPage = Math.ceil(total / state.pagination1.pageSize);
          if (lastPage < state.pagination1.pageIndex) {
            state.pagination1.pageIndex = lastPage;
            state.searchParam1.pageIndex = lastPage;
          }
          initDictItemList(state.searchParam1);
        }
      });
    };

    const initDictList = (searchParam) => {
      state.loading = true;
      getDictionaryListPage(searchParam).then((resp) => {
        if (resp) {
          state.dictList = resp.obj.list;
          state.searchParam1.code = state.dictList[0].code;
          state.currentName = state.dictList[0].name;
          initDictItemList(state.searchParam1);
          state.pagination.total = resp.obj.pagination.total;
          state.loading = false;
        }
      });
    };
    const initDictItemList = (searchParam) => {
      state.loading1 = true;
      getDictItemListPage(searchParam).then((resp) => {
        if (resp) {
          state.dictItemList = resp.obj.list;
          state.pagination1.total = resp.obj.pagination.total;
          state.loading1 = false;
        }
      });
    };
    const refData = toRefs(state);
    return {
      ...refData,
      onSearch,
      onSearchChange,
      onChangePage,
      onShowSizeChange,
      onChangePage1,
      onShowSizeChange1,
      customRow,
      editableData,
      editableData1,
      handleAdd,
      handleAdd1,
      handleEdit,
      handleEdit1,
      handleSave,
      handleSave1,
      handleCancel,
      handleCancel1,
      handleDelete,
      handleDelete1,
      initDictList,
      initDictItemList,
    };
  },
});
</script>
<style lang='scss' scoped>
.tool {
  display: flex;
  justify-content: space-between;
}
.divAreaCss {
  border: 1px solid #409eff;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0px;
}
.pagination {
  margin: 6px 0px;
  padding: 6px;
  display: flex;
  justify-content: flex-end;
  background-color: rgba(183, 217, 241, 0.5);
}
.ant-card-head {
  height: 36px;
  padding: 0 10px;
}
.ant-card-head-wrapper {
  height: 36px;
}
.ant-card-body {
  padding: 4px;
}
.ant-card-head-title {
  height: 36px;
  color: #3805c4;
  padding: 6px;
}
.ant-table.ant-table-middle
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-tbody
  > tr
  > td {
  padding: 6px 6px;
}
.ant-table.ant-table-middle
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-thead
  > tr
  > th {
  padding: 6px 6px;
  background-color: rgba(183, 217, 241, 0.5);
}
</style>